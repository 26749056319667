export default {
    colors: {
        logo: "",              // SVG logo fill
        border: "#eeeeee",            // Theme border color
        bg_topbar: "",         // Topbar background
        bg_header: "",         // Header background
        bg_mobilenav: "",      // MobileNav background
        bg_sidenav: "",        // SideNav background
        bg_tabbar: "",         // TabBar background
        bg_footer: "",         // Footer background
        text_navlink: "",      // Header link color
        text_topbar: "",       // Topbar text color
        modes: {
            dark:{
                text: "#ffffff",
                background: "#1C1C1C",
                bg_header: "#1C1C1C",
            }
        }
    },
    shadows: {
        header: "",            // Header shadow
        tabbar: "",            // TabBar shadow
    },
    fonts: {
        body: '"Apercu"',
    },
    fontSizes: [
        12, 14, 16, 20, 24, 32, 48, 64,
    ],
    text: {
        heading: {
          fontFamily: 'heading',
          fontWeight: 'heading',
          lineHeight: 'heading',
          fontSize: 5
        }
    },    
}
/*
    CORES DO SITE
    --black: #000;
    --white: #fff;
    --E-grey: #eee;
    --D-grey: #ddd;
    --C-grey: #ccc;
    --B-grey: #bbb;
    --A-grey: #aaa;
    --main-blue: #4285f4;
    --main-green: #2bab27;
    --main-red: #dd2020;
    --main-yellow: #e2b433;
    --main-grey: rgba(0,0,0,0.45);
    --secondary-blue: #356ac0;
    --secondary-green: #008001;
    --secondary-red: #6f0000;
    --secondary-yellow: #af8c2d;
    --secondary-grey: rgba(0,0,0,0.6);
    --hover-red: #db4437;
    --qzela-background: #f0f2f5;
    --qzela-blue: #0f2e67;
    --qzela-card: rgba(0,0,0,0.4);
    --qzela-font-family: "Bai Jamjuree",sans-serif;
    --qzela-icon-prefix: rgba(0,0,0,0.25);
    --qzela-orange: #dd5801;
    --qzela-sider-transition: all 100ms linear;
    --box-shadow: rgba(0,0,0,0.3) 0px 1px 4px -1px;
}    
*/