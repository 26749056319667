import React, {useContext, useEffect, useState} from "react"
import { Link } from "gatsby"
import '../styles/global.css'
import {Facebook, Twitter, Instagram, Youtube, Linkedin, Mail, Brazil, UnitedStates, Dark, Light} from './icons'
import {GlobalDispatchContext, GlobalStateContext} from '../context/GlobalContextProvider'
import { Layout, Header, ContentWrapper, Logo, NavMenu, Main, MobileNav, MenuToggle, Footer } from 'gatsby-theme-elements'
import flex from '../styles/flexbox.module.css';
import { ThemeToggler } from 'gatsby-plugin-dark-mode';

const LayoutMaster = ({location, title, children}) => {
  const dispatch = useContext(GlobalDispatchContext)
  const [lightTheme, setLightTheme] = useState(false)
  const [bodyState, setBodyState] = useState()
  const state = useContext(GlobalStateContext)
  const {menu, footer} = state.language
  const rootPath = `${__PATH_PREFIX__}/`
  // let bodyLayout = () => typeof document !== `undefined` ? document.querySelector('body') : null 

  useEffect(()=>{
    setBodyState(document.querySelector('body'))
    let bodyLayout = () => document.querySelector('body')
    if(localStorage.getItem('theme-ui-color-mode')==='light'){
      localStorage.setItem('theme', 'light')
      setLightTheme(true)
      bodyLayout().classList.remove('dark')
    }
    else{
      bodyLayout().classList.add('dark')
      setLightTheme(false)
      localStorage.setItem('theme', 'dark')
    }
  }, [])

  const socialMedias = [
    {url: 'https://www.facebook.com/qzela/', icon: <Facebook className={`social-icons`} width="25" height="25" />},
    {url: 'https://twitter.com/Qzela_app', icon: <Twitter className={`social-icons`} width="25" height="25" />},
    {url: 'https://www.instagram.com/qzela_app/?igshid=10h9bbfz3mxy4', icon: <Instagram className={`social-icons`} width="25" height="25" />},
    {url: 'https://www.linkedin.com/company/qzela', icon: <Linkedin className={`social-icons`} width="25" height="25" />},
    {url: 'https://www.youtube.com/channel/UCwBPrpupMDfiIsIOfIfcCbQ', icon: <Youtube className={`social-icons`} width="25" height="25" />},
  ]

  const realoadPage = (link) =>{
    if(location.pathname === link) location.reload()
  }

    return (
      <Layout>
        <Header style={{display: 'flex', justifyContent: 'space-between'}}>
            <Logo>
              <img className="main-logo" src="https://storage.googleapis.com/assets.qzela.com.br/images/landing/logo/QZ_logo_horizontal.png" alt=""/>
            </Logo>
          <div className={`${flex.container} ${flex.justifyContent} ${flex.justifySpaceBetween} ${flex.alignCenter} ${flex.smallSpacing}`}>
            <NavMenu className={`${flex.container} ${flex.justifySpaceEvenly} ${flex.alignCenter}`}>
              {menu.map((item, index)=>(
                index!==7 && index!==8 ? <Link key={index} className="nav-link" to={item.link}>{item.text}</Link> : ''
              ))}
            </NavMenu>
            <section className={`${flex.container} ${flex.justifyContent} ${flex.justifySpaceBetween} ${flex.alignCenter} ${flex.smallSpacing} ${flex.directionRow}`}>
              <button className="icon" onClick={()=>{dispatch({type: "TOGGLE_LANGUAGE"})}}>
                {state.language.current==='portuguese' ? <UnitedStates className={`social-icons`} height="25" /> : <Brazil className={`social-icons`} height="25" />}
              </button>
              <ThemeToggler>
                {({ theme, toggleTheme }) => (
                  <>
                    <label className="icon">
                      <input hidden
                        type="checkbox"
                        onChange={e => toggleTheme(e.target.checked ? 'dark' : 'light')}
                        checked={theme === 'dark'}
                      />
                      {bodyState && !bodyState.classList.contains('dark') ? <Dark className={`social-icons`} height="20" /> : <Light className={`social-icons`} height="20" />}
                    </label>
                    <MenuToggle icon="menu" fill={`${bodyState && !bodyState.classList.contains('dark') ? '#0f2e67' : '#4285f4'}`} />
                  </>
                )}
              </ThemeToggler>
            </section>
          </div>
          <MobileNav defaultClose={true} className="menu-mobile" fill="#dd5801">
            {/* <MenuToggle icon="close" /> */}
            <section style={{display:'flex', flexDirection: 'column', alignItens: 'stretch', justifyContent: 'center'}}>
              {menu.map((item, index)=>(
                <Link key={index} className="nav-link" to={item.link} onClick={()=>realoadPage(item.link)}>{item.text}</Link>
              ))}
            </section>
          </MobileNav>
        </Header>
        <ContentWrapper>
            {/* <SideNav /> */}
            <Main className={`min-full-height ${location.pathname === rootPath ? 'main-content': ''}`}>{children}</Main>
            <Footer className={`${flex.container} ${flex.justifySpaceBetween} ${flex.spacing} ${flex.alignCenter}`}>
              <div>
                © {new Date().getFullYear()}, QZela
                {` `}
                - Designed by&nbsp;<a href="http://www.westarstg.com.br/" target="_blank" rel="noopener noreferrer">WestarsTG</a>&nbsp;|&nbsp;
                <Link key={footer.terms} to="/terms/">{footer.terms}</Link>&nbsp;•&nbsp;
                <Link key={footer.privacy} to="/privacy/">{footer.privacy}</Link>
              </div>
              <div className={`${flex.container} ${flex.justifySpaceAround} ${flex.spacing} ${flex.alignCenter} ${flex.directionRow}`}>
                {socialMedias.map((item, index)=>(
                  <a key={index} href={item.url} className={flex.selfCenter} target="_blank" rel="noopener noreferrer">
                    {item.icon}
                  </a>
                ))}
                <Link to="/contact/" className={flex.selfCenter}>
                  <Mail className={`social-icons`} width="25" height="25" />
                </Link>
              </div>
            </Footer>
        </ContentWrapper>
      </Layout>  
    )
}

export default LayoutMaster
